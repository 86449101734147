.banner {
  background-color: white;
  width: 100%;
  color: #009F97;
}

.announcementBar {
  display: flex;
  margin-bottom: auto;
  background-color: #009F97;
  height: 34px;
  color: white;
}

.announcementContainer {
  composes: container from '../../App.module.css';
  width: 100%;
}

.bannerLogo {
  margin: 10px auto;
  max-height: 100px;
}

.bannerItem {
  composes: verticalAlign from '../../App.module.css';
}

.bannerResponsiveItem {
  composes: verticalAlign from '../../App.module.css';
  composes: flexRight from '../../App.module.css';
}

@media (max-width: 900px) {
  .bannerItem {
    display: none;
  }
  .announcementContainer {
    font-size: 8px;
  }
  .bannerResponsiveItem {
    margin-left: auto;
    margin-right: auto;
  }
}